<template>
  <div class="d-flex align-center align-text-center main container">
    <el-card class="card">
      <img src="/images/MySideline_PrimaryLogo_black.svg" />
      <h3>Down for Maintenance</h3>
      <p>Please bear with us we will be offline.</p>
      <a href="#" @click.prevent="$router.go(-1)">Go Back</a>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "UnderConstruction"
};
</script>

<style scoped lang="scss">
.main {
  max-width: 500px;
}
.card {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  text-align: center;

  img {
    width: 70%;
  }
}
</style>
